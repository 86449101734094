import sal from "sal.js";

// Menu UI Functions
const menuToggleFunc = () => {
  const mTrigger = document.getElementById("js-menu-trigger");
  const jsInnerLink = document.querySelectorAll('.js-inner-link');
  jsInnerLink.forEach((link) => {
    link.addEventListener('click', () => {
      document.body.classList.remove('menu-show');
    });
  });

  mTrigger.addEventListener("click", () => {
    if (document.body.classList.contains('menu-show')) {
      document.body.classList.remove('menu-show');
    } else {
      document.body.classList.add('menu-show');
    }
  });

}

// dt & dd Accordion
const accordionFunc = () => {
  const accordionTrigger = document.querySelectorAll('.js-accordion-trigger');
  accordionTrigger.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      console.log(trigger.nextElementSibling.classList);
      if (trigger.nextElementSibling.classList.contains('open')) {
        trigger.nextElementSibling.classList.remove('open');
        trigger.classList.remove('open');
      } else {
        trigger.nextElementSibling.classList.add('open');
        trigger.classList.add('open');

      }
    });
  });
}


// スクロールイベント
window.addEventListener('scroll', () => {

});

// ページ表示時イベント
document.addEventListener('DOMContentLoaded', () => {
  menuToggleFunc();
  accordionFunc();
  sal();

});

// ページ読み込み完了時イベント
window.addEventListener('load', () => {

});

// ページリサイズ時イベント
window.addEventListener('resize', () => {
});
